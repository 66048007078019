import styles from './ContactUs.module.scss';
import { Col, Row } from 'components/primitives/grid';
import ContactUsForm from './ContactUsForm';
import { SimpleText, RichText } from 'components/sanaText';

const ContactUs = () => (
  <Row id="contactUsPage">
    <Col xs={12}>
      <RichText textKey="ContactUs_Body" />
    </Col>
    <Col xs={12} md={8} lg={7}>
      <h2><SimpleText textKey="ContactForm_Email" /></h2>
      <div className={styles.formHolder}>
        <ContactUsForm />
      </div>
    </Col>
  </Row>
);

export default ContactUs;