import styles from 'components/objects/forms/SanaForm.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SanaForm, FormGroup } from 'components/objects/forms';
import { EmailAddressField } from 'components/objects/forms';
import { SanaButton } from 'components/primitives/buttons';
import { useSimpleTexts } from 'components/sanaText';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const NewsletterForm = ({ onSubmit, shouldReset, buttonTextKey, ...attributes }) => {
  const [emailTitle] = useSimpleTexts(['EmailAddress']).texts;

  return (
    <SanaForm onSubmit={useHandlerLockerInPreview(onSubmit)} shouldReset={shouldReset} {...attributes}>
      <FormGroup
        fieldName="email"
        fieldTitle={emailTitle}
        placeholder={emailTitle}
        fieldComponent={EmailAddressField}
        maxLength={80}
        required
        validation={{
          required: true,
        }}
      />
      <div className={styles.formRow + ' ' + styles.rowActions}>
        <SanaButton textKey={buttonTextKey} type="submit" className={btnStyles.btnBig + ' ' + styles.btn} />
      </div>
    </SanaForm>
  );
};

NewsletterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  buttonTextKey: PropTypes.string.isRequired,
  shouldReset: PropTypes.bool,
};

export default memo(NewsletterForm);
