import styles from '../Subscribe.module.scss';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { RichText } from 'components/sanaText';
import { connect } from 'react-redux';
import { subscribeToNewsletter } from 'behavior/newsletter';
import NewsletterForm from '../NewsletterForm';
import { useSuccessMessage } from '../hooks';
import { generateKey } from 'utils/helpers';

const Subscribe = ({ lastUpdated, updatedById, subscribeToNewsletter }) => {
  const [componentId] = useState(generateKey);
  const subscribedByComponent = componentId === updatedById;

  const onSubmit = useCallback(formData => {
    subscribeToNewsletter(formData, componentId);
  }, []);

  useSuccessMessage(lastUpdated, subscribedByComponent, 'NewsletterSubscribedSuccessfully');

  return (
    <>
      <div className={styles.intro}>
        <RichText textKey="NewsletterSubscribe_Description" />
      </div>
      <Row>
        <Col md={8} lg={7}>
          <NewsletterForm
            name="NewsletterSubscribe"
            buttonTextKey="Subscribe"
            onSubmit={onSubmit}
            shouldReset={subscribedByComponent}
            className={styles.form}
          />
        </Col>
      </Row>
    </>
  );
};

Subscribe.propTypes = {
  subscribeToNewsletter: PropTypes.func.isRequired,
  lastUpdated: PropTypes.instanceOf(Date),
  updatedById: PropTypes.string,
};

export default connect(
  ({ newsletter: { lastUpdated, updatedById } }) => ({ lastUpdated, updatedById }),
  { subscribeToNewsletter },
)(Subscribe);
